.PDFHeader{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    overflow: hidden;
}
.LeftHeader{
    background-color: #082872;
    color: #fff;
    height: 205px;
    width: 330px;
    overflow: hidden;
    margin-left: -3em;
    margin-top: 1px;
    padding-left: 3.5em;
    padding-top: 25px;
    font-size: 20px;
    font-weight: bold;
}
.RightHeader{
    background-color: #4aabe7;
    color: #fff;
    height: 207px;
    width: 600px;
    overflow: hidden;
    transform: skewX(-5deg);
    padding: 5em;
    background-repeat: no-repeat;
    margin: 0 -4em;
}