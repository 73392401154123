.button_imprimer_validateur{
    position: fixed;
    right: 266px;
    top: 25px;
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    width: 125px;
    height: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    z-index: 10;
}
.ImprimerValidateur_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.5);

}
.ImprimerValidateur_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    display: flex;
    align-items: center;
}
.ImprimerValidateur_modal{
    z-index: 100;
    background: #fff;
    position: relative;
    margin: auto;
    border-radius: 5px;
    max-width: 500px;
    width: 80%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.ImprimerValidateur_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    height: 78px;
    width: 500px;
    background-color: #072872;
    color: #fff;
    padding: 1rem;
}
.ImprimerValidateur_header button {
    border-radius: 20px;
    width: 44px;
    height: 44px;
    background-color: #7ECAC8;
    border: 1px solid #7ECAC8;
    color: #fff;
}
.modal_button_imprimer_validateur{
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    width: 348px;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    margin-bottom: 37px;
}