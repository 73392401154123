.outer-div{
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.inner-div{
    display: inline-block;
}

/* MENU TOP */

.menu_top{
    height: 300px;
    display: flex;
    align-items: center;
}
.menu_top div{
    width: 128px;
    margin-right: 40px;
}

.menu_top h2{
    margin-top: 20px;
    font-size: 18px; 
}
.menu_top a{
    text-decoration: none;
    color: black;
}

/* MENU BOT */

.menu_bot{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.menu_bot div{
    width: 128px;
    margin-right: 40px;
}

.menu_bot h2{
    margin-top: 20px;
    font-size: 18px; 
}
.menu_bot a{
    text-decoration: none;
    color: black;
}

.pt_menu{
    position: static;
    width: 128px;
    height: 128px;
    background: linear-gradient(135.56deg, #4AABE7 13.17%, #2F9CDE 89.13%);
    border-radius: 22px;
    text-align: center;
}
.pt_menu img{
    margin-top: 30px;
}
.tc_menu{
    position: static;
    width: 128px;
    height: 128px;
    background: #76C6DF;
    border-radius: 22px;
}
.tc_menu img{
    margin-top: 20px;
}
.pp_menu{
    position: static;
    width: 128px;
    height: 128px;
    background: linear-gradient(137.99deg, #7ECAC8 11.62%, #1BA7A3 92.71%);
    border-radius: 22px;
}
.pp_menu img{
    margin-top: 30px;
}
.vigilance_menu{
    position: static;
    width: 128px;
    height: 128px;
    background: linear-gradient(137.17deg, #76C6DF 11.71%, rgba(46, 168, 207, 0.96) 96.76%);
    border-radius: 22px;
}
.vigilance_menu img{
    margin-top: 20px;
}
.loto_menu{
    position: static;
    width: 128px;
    height: 128px;
    background: linear-gradient(137.17deg, #76C6DF 11.71%, rgba(46, 168, 207, 0.96) 96.76%);
    border-radius: 22px;
}
.loto_menu img{
    margin-top: 30px;
}
.reglage_user{
    position: static;
    width: 128px;
    height: 128px;
    background: rgba(69, 69, 69, 0.5);
    border-radius: 22px;
}
.reglage_user img{
    margin-top: 30px;
}
.reglage_admin{
    position: static;
    width: 128px;
    height: 128px;
    background: #1A1C1D;
    border-radius: 22px;
}
.reglage_admin img{
    margin-top: 30px;
}