@media screen{
    .PDFValidateurContent{
        display: none;
    }
}
table { 
    table-layout: fixed;
    border-collapse: collapse; 
    border-radius: 10px;
    overflow: hidden;
    max-width: 760px; 
    margin: 25px auto;
    position: relative;
}
thead{
    background-color: #6ebcec;
    color: #fff;
    font-size: 25px;
}
thead th:nth-child(1) {
    width: 30%;
}

thead th:nth-child(2) {
    width: 20%;
}

thead th:nth-child(3) {
    width: 50%;
}
tr{
    
    border: 2px solid #6ebcec;
}
th, td {
    padding: 35px;
    text-align: center;
}