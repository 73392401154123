.navbar_newpt{
    padding-left: 12px;
    height: 100vh;
    width: 220px;
    background-color: #439EDB;
    color: #fff;
    position: fixed;
    top: 0;
    left: 220px;
}
.numero_newpt{
    height: 86px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;
}
.numero_newpt p{
    font-size: 14px;
    margin-bottom: 5px;
}
.content_navbar_newpt{
    margin-top: 65px;
}
.navlink_newpt{
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    width: 190px;
    height: 35px;
    border: 1px solid #439EDB;
    background-color: #e9ebf01a;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-top: 12px;
    margin-bottom: 24px;
}

