.navbar_pt{
    height: 100vh;
    width: 220px;
    background-color: #072872;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
}
.nav-link{
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    width: 190px;
    height: 35px;
    border: 1px solid #072872;
    background-color: #e9ebf01a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 12px;
    margin-bottom: 24px;
}

.top_navbar{
    padding-top: 12px;
    margin-left: 12px;
}
.pt_navbar{
    margin-left: 12px;
}