.filtre{
    margin-left: 220px;
}
.bouton_pt{
    margin-right: 60px;
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    width: 100px;
    height: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
}
.bandeau_filtre{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 86px;
    padding-left: 280px;
    height: 100px;
    background-color: #7ECAC8;
    display: flex;
    justify-content: center;
    align-items: center;
}
.searchBar{
    margin-right: 50px;
}
.filtre_numero input{
    width: 73px;
    height: 34px;
    
}
.filtre_titre input{
    margin-bottom: 5px;
    width: 280px;
}
.filtre_nom input{
    margin-bottom: 5px;
    width: 280px;
}
.filtre_datedebut{
    margin-bottom: 5px;
}