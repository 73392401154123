.header_pt{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin-left: 220px;
    height: 86px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #072872;
    color: #fff;
    
}
.titre_page{
    margin-left: 60px;
}
