.tableau_pt{
    margin-left: 220px;
    margin-top: 200px;
}
.tableau_header{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: grey;
    padding: 10px 64px 20px 64px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.tableau_content{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 70px;
    margin: 20px 64px 0 64px;
    background-color: #F5F5F5;
    
}