.content_recommandations{
    color: black;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 405px;
}
.content_organewpt_input{
    display: flex;
    flex-direction: column;
}
.content_organewpt_input input{
    width: 405px;
    height: 50px;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    margin-top: 10px;
    padding-left: 10px;
}
.titre_bleu_recommandation{
    color: #4aabe7;
    margin-top: 20px;
    margin-bottom: 20px;
}
.intervention_recommandations p{
    color: #8c8d8e;
}
.st_recommandation{
    margin-bottom: 10px;
    margin-top: 10px;
}
.last_para{
    margin-bottom: 50px;
}