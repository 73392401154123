.contentIntervenantNewPT{
    position: fixed;
    left: 405px;
    top: 86px;
    margin-left: 35px;
}
.bandeauDateI{
    background-color: #4aabe7;
    height: 77px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding-left: 62px;
    padding-right: 562px;
}
.dateJourI{
    font-size: 36px;
}
.searchI button{
    width: 170px;
    background-color: #7ecac8;
    height: 40px;
    padding: 10px;
    color: #fff;
    border: 1px solid #7ecac8;
    border-radius: 5px;
    margin-right: 25px;
}
.searchI button span{
    margin-left: 5px;
}