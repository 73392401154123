.button_add_validateur{
    position: fixed;
    right: 31px;
    top: 25px;
    border: 1px solid #7ECAC8;
    border-radius: 5px;
    background-color: #7ECAC8;
    width: 215px;
    height: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    z-index: 1000;
}
.AddValidateur_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.5);

}
.AddValidateur_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    display: flex;
    align-items: center;
}
.AddValidateur_modal{
    z-index: 100;
    background: #fff;
    position: relative;
    margin: auto;
    border-radius: 5px;
    max-width: 500px;
    width: 80%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.AddValidateur_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    height: 78px;
    width: 500px;
    background-color: #072872;
    color: #fff;
    padding: 1rem;
}
.AddValidateur_header button {
    border-radius: 20px;
    width: 44px;
    height: 44px;
    background-color: #7ECAC8;
    border: 1px solid #7ECAC8;
    color: #fff;
}
.AddValidateur_content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AddValidateur_content input{
    width: 350px;
    height: 50px;
    margin-bottom: 15px;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    padding-left: 10px;
    border-radius: 5px;
}