.menu_contextuel button{
    border: 0;
    background-color: transparent;
}

.modal_mc{
    background-color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    width: 180px;
    height: 290px;
}
.modal_mc div{
    margin-top: 6px;
    padding-bottom: 6px;
    width: 145px;
    font-size: 14px;
    border-bottom: 1px solid black;
}