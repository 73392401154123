.header_newpt{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin-left: 440px;
    height: 86px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #439EDB;
    color: #fff;
    z-index: 5;
}
.titre_page_newpt{
    margin-left: 60px;
}
