.outerbox_newpt{
    position: relative;
    top: 150px;
    left: 300px;
}
.content_descnewpt{
    color: black;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 405px;
}
.content_descnewpt input {
    width: 405px;
    height: 50px;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    margin-top: 10px;
    padding-left: 10px;
}
.name_newpt{
    font-weight: bold;
    margin-top: 26px;
}
.info_rdp_newpt{
    margin-top: 26px;
}
.info_rdp_newpt p{
    color: #8c8d8e;
    padding-bottom: 20px;
}
.content_descnewpt_input{
    display: flex;
    flex-direction: column;
}
.content_descnewpt_input textarea {
    height: 240px;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    margin-bottom: 100px;
    border-radius: 5px;
}
.button_add_do button{
    width: 405px;
    height: 50px;
    background-color: #fff;
    color: #4aabe7;
    border: 2px solid #4aabe7;
    margin-bottom: 20px;
    margin-top: 26px;
    border-radius: 5px;
}
.button_add_do button:hover{
    background-color: #4aabe7;
    color: #fff;
}
.border_grey_newpt{
    border-bottom: 1px solid #8c8d8e;
}